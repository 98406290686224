import { Alert, Loader } from '@maersktankersdigital/web-components'
import { Box } from '@mui/material'
import { FC } from 'react'
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useParams,
} from 'react-router-dom'
import { RouterLink } from '~components/atoms/router-link'
import { NavButton } from '~components/atoms/tab/nav-button'
import { useHasRole } from '~hooks/permissions/use-has-role'
import { ExactScopeName, useHasScope } from '~hooks/permissions/use-has-scope'
import { useIsMtOpsUser } from '~hooks/permissions/use-is-mt-ops-user'
import { useGetMe } from '~hooks/queries/me/use-get-me'
import { useNavAndFooterConstants } from '~hooks/use-nav-and-footer-constants'
import { COLORS } from '~theme/colors'
import { getActiveRoute } from '~utils/get-active-route'
import { PageRoute } from '../../../../routes/routes-behind-login/vt-routing/constants/page-route'
import { StaticPageAttributes } from '../../../../routes/routes-behind-login/vt-routing/constants/routes'
import {
  StyledVesselPageNav,
  StyledVesselPageNavStickyWrapper,
} from './vessel-page-nav.styles'

type VesselPageNavProps = {
  pageConfigs: StaticPageAttributes
}

export const VesselPageNav: FC<VesselPageNavProps> = ({ pageConfigs }) => {
  const { isLoading: isUserLoading, error: userError } = useGetMe()
  const { footerHeight } = useNavAndFooterConstants()

  if (userError) {
    return (
      <div>
        <Alert
          variant="error"
          text="Oops! There was an error fetching the pages you're allowed to visit. Please reload the page or try again later"
        />
      </div>
    )
  }

  return (
    <Box
      sx={{
        minHeight: '100vh',
        pb: footerHeight,
        bgcolor: COLORS.secondary.darkBlue,
      }}
    >
      <StyledVesselPageNav>
        <StyledVesselPageNavStickyWrapper>
          {isUserLoading ? (
            <Loader />
          ) : (
            <VesselPageNavInner pageConfigs={pageConfigs!} />
          )}
        </StyledVesselPageNavStickyWrapper>
      </StyledVesselPageNav>
    </Box>
  )
}

type VesselPageNavInnerProps = {
  pageConfigs: StaticPageAttributes
}

function hasRoute(pageConfigs: StaticPageAttributes, route: string) {
  return Object.keys(pageConfigs).some((slug) => slug === route)
}

function useConstructLinkToMainTab(pageConfigs: StaticPageAttributes) {
  const isMtOps = useIsMtOpsUser()
  const isCrew = useHasRole({ entity: 'vessel', role: 'crew' })
  const isPoolPartner = useHasScope(ExactScopeName.poolPartner)

  const hasOpsDashboard = hasRoute(pageConfigs, PageRoute.OPS_DASHBOARD)
  const hasVesselDashboard = hasRoute(pageConfigs, PageRoute.VESSEL_DASHBOARD)
  const hasPartnerDashboard = hasRoute(pageConfigs, PageRoute.PARTNER_DASHBOARD)
  const hasDocumentPage = hasRoute(pageConfigs, PageRoute.DOCUMENTS)

  if (isMtOps && hasOpsDashboard) return PageRoute.OPS_DASHBOARD
  if (isCrew && hasDocumentPage) return PageRoute.DOCUMENTS
  if (isPoolPartner && hasPartnerDashboard && !hasVesselDashboard)
    return PageRoute.PARTNER_DASHBOARD
  if (!hasVesselDashboard) return PageRoute.QUESTIONNAIRE

  return PageRoute.VESSEL_DASHBOARD
}

const VesselPageNavInner = ({ pageConfigs }: VesselPageNavInnerProps) => {
  const routeParams = useParams()
  const { pathname } = useLocation()
  const linkToMainTab = useConstructLinkToMainTab(pageConfigs)
  const [, activeRoute] = getActiveRoute(pathname, routeParams)

  return (
    <>
      <Routes>
        <Route path="" element={<Navigate replace to={linkToMainTab} />} />
      </Routes>

      {Object.keys(pageConfigs).map((route) => {
        const obj = pageConfigs[route as keyof StaticPageAttributes]
        const isActive = activeRoute === route

        return (
          <RouterLink
            to={route}
            params={{ vesselId: routeParams.vesselId }}
            replace
            // @ts-ignore
            key={obj?.id}
          >
            <NavButton isSelected={isActive} size="xSmall">
              {/* @ts-ignore */}
              {obj?.title}
            </NavButton>
          </RouterLink>
        )
      })}
    </>
  )
}
