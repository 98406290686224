import { PageWrapper, Typography } from '@maersktankersdigital/web-components'
import { IVesselsReadResponse } from '~api/vessels/read'
import { InfoLabel } from '~components/atoms/info-label/info-label'
import { DeliveryLabel } from '~pages/pages-behind-login/vessel-page/delivery-label/delivery-label'
import { RedeliveryLabel } from '~pages/pages-behind-login/vessel-page/redelivery-label/redelivery-label'
import { HeaderActions } from '~pages/pages-behind-login/vessel-page/vessel-header/header-actions/header-actions'
import { HeaderDetails } from '~pages/pages-behind-login/vessel-page/vessel-header/header-details/header-details'
import { useVesselPageState } from '~pages/pages-behind-login/vessel-page/vessel-page-provider'
import { Z_INDEX } from '~types/z-index'

export enum TrackFieldValue {
  EARNINGS_SIMULATION = 'earnings-simulation',
  NORMAL = 'normal',
  COMMERCIAL_MANAGEMENT = 'commercial-management',
  ONBOARDING = 'onboarding',
}

import { Box } from '@mui/material'
import { useNavAndFooterConstants } from '~hooks/use-nav-and-footer-constants'
import { COLORS } from '~theme/colors'
import { StyledHeadlineAndActionsContainer } from './vessel-header.styles'

export function VesselHeader({
  vessel,
  pageConfig,
}: {
  pageConfig: any | null
  vessel: IVesselsReadResponse
}) {
  const { topNavigationHeight } = useNavAndFooterConstants()
  const state = useVesselPageState()
  const isCommercialManagement =
    vessel.status === TrackFieldValue.COMMERCIAL_MANAGEMENT

  return (
    <Box
      sx={{
        position: 'sticky',
        top: topNavigationHeight,
        left: 0,
        background: COLORS.greys.ultraLight,
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.1)',
        zIndex: Z_INDEX.PageHeader,
      }}
    >
      <Box component={PageWrapper} variant="wide" sx={{ py: 4 }}>
        <Box
          sx={{
            display: 'flex',
            gap: 3,
            justifyContent: 'space-between',
            flexWrap: 'wrap',
          }}
        >
          <Box
            sx={{ display: 'flex', gap: { xs: 5, xl: 7 }, flexWrap: 'wrap' }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <StyledHeadlineAndActionsContainer>
                <Typography variant="headline1">{vessel.vesselName}</Typography>
                <HeaderActions vesselData={vessel} />
              </StyledHeadlineAndActionsContainer>
              {isCommercialManagement && (
                <Typography variant="menu">Commercial Management</Typography>
              )}
            </Box>

            <HeaderDetails vessel={vessel} />
          </Box>
          {pageConfig?.header?.buttonComponentFn?.(state)}
        </Box>

        <Box sx={{ display: 'flex', gap: 2, mt: 4, flexWrap: 'wrap' }}>
          <DeliveryLabel />
          <RedeliveryLabel />
          {state.isVesselArchived && (
            <InfoLabel text="archived" variant="VesselDashboard" />
          )}
        </Box>
      </Box>
    </Box>
  )
}
