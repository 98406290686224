import { Button, FileCopyIcon } from '@maersktankersdigital/web-components'
import { Box, Checkbox, FormControlLabel } from '@mui/material'
import { FC, useContext, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ApiRoutes } from '~api'
import { IVesselsReadResponse } from '~api/vessels/read'
import { useAnimatedAlert } from '~components/molecules/animated-alert/animated-alert-provider'
import {
  getActiveItineraryItem,
  getCurrentVoyage,
  getCurrentVoyageStatus,
  getNextItineraryItem,
} from '~components/molecules/voyage-tracker/voyage-tracker.utils'
import {
  getLastSire,
  getLastThreeVoyages,
} from '~components/organisms/dashboard/dashboard.utils'
import { DashboardModalContext } from '~components/organisms/modals/dashboard/dashboard-modal'
import { useGetTabData } from '~hooks/queries/vessels/data/use-get-tab-data'
import { useGetVettings } from '~hooks/queries/vettings/use-get-vettings'
import { useGetAllTenVoyages } from '~hooks/queries/voyages/use-get-all-ten-voyages'
import { useApi } from '~hooks/use-api'
import { PortFunction } from '~types/itinerary.types'
import { capitalizeStringWords } from '~utils/capitalize-string-words'
import { copyToClipboard } from '~utils/copy-to-clipboard'
import { toDateFormat, toDateTimeFormat } from '~utils/dates'
import { gtm } from '~utils/gtm'

const defaultFormState = {
  lastThreeCargoSelected: false,
  lastSireSelected: false,
  ownersDetailsSelected: false,
  itinerarySelected: false,
}

const portFunctionPortPrefix: Record<PortFunction, string> = {
  [PortFunction.COMMENCING]: 'Commencing',
  [PortFunction.FUELING]: 'Fueling',
  [PortFunction.LOADING]: 'Load',
  [PortFunction.WAITING]: 'Waiting',
  [PortFunction.DISCHARGING]: 'Discharge',
  [PortFunction.OTHER]: 'Other',
  [PortFunction.CANAL_TRANSIT]: 'Canal Transit',
  [PortFunction.REPAIR]: 'Repair',
  [PortFunction.CHARTERERS_ORDERS]: "Charterer's Orders",
  [PortFunction.TERMINATING]: 'Terminating',
  [PortFunction.DELIVERY]: 'Delivery',
  [PortFunction.FOR_ORDERS]: 'For Orders',
  [PortFunction.MULTI_PURPOSE]: 'Multi-Purpose',
  [PortFunction.REDELIVERY]: 'Redelivery',
  [PortFunction.PASSING]: 'Passing',
}

const PORT_FUNCTION_LABELData: Record<string, string> = Object.keys(
  portFunctionPortPrefix,
).reduce(
  (prevVal, key) => ({
    ...prevVal,
    [key]: `${portFunctionPortPrefix[key as PortFunction]} port`,
  }),
  {},
)

export const CopyDashboardToClipboardTooltipContent: FC = () => {
  const hiddenAreaWithCopiedDataRef = useRef<HTMLDivElement>(null)
  const { closeModal } = useContext(DashboardModalContext)
  const { setStatus } = useAnimatedAlert()

  const { vesselId } = useParams()
  const { data: dashboardData } = useGetTabData('vesseldashboard', vesselId)
  const { data: vesselData } = useApi<IVesselsReadResponse>(
    ApiRoutes.Vessels.read,
    {
      vesselId,
    },
  )
  const { data: voyageData } = useGetAllTenVoyages(vesselId)
  const { data: sireData } = useGetVettings('SIRE', vesselId)

  const lastThreeVoyages = getLastThreeVoyages(voyageData)
  const lastSire = getLastSire(sireData)
  const bankingDetails = dashboardData?.bankingDetails
  const registeredOwner = dashboardData?.registeredOwner
  const commercialOperator = dashboardData?.commercialOperator
  const disponentOwner = dashboardData?.disponentOwner
  const technicalOperator = dashboardData?.technicalOperator

  const currentVoyage = getCurrentVoyage(voyageData)
  const itineraryData = currentVoyage?.itineraries ?? []

  const upcomingItineraryItem = getNextItineraryItem(itineraryData)
  const upcomingItineraryItemIndex = itineraryData.findIndex(
    (data) => data === upcomingItineraryItem,
  )

  const activeItineraryItem = getActiveItineraryItem(itineraryData)
  const activeItineraryItemIndex = itineraryData.findIndex(
    (data) => data === activeItineraryItem,
  )

  const upcomingItineraryData = itineraryData.filter((_, index) => {
    const itemIndex =
      upcomingItineraryItemIndex >= 0
        ? upcomingItineraryItemIndex
        : (activeItineraryItemIndex ?? 0)

    return index >= itemIndex
  })

  const upcomingDischargePorts = upcomingItineraryData.filter(
    ({ portFunction }) => portFunction === PortFunction.DISCHARGING,
  )
  const currentVoyageStatus = currentVoyage
    ? getCurrentVoyageStatus(currentVoyage)
    : undefined

  const copyToClipboardHandler = () => {
    copyToClipboard(hiddenAreaWithCopiedDataRef.current?.innerHTML || '')
    setStatus(
      'Your selection has successfully been copied to the clipboard',
      undefined,
      'success',
    )
    gtm.pushEvent('selection_copied_to_clipboard')
    if (closeModal) {
      setTimeout(() => {
        closeModal()
      }, 0)
    }
  }

  const [formState, setFormState] = useState(defaultFormState)

  const allSelected =
    formState.lastThreeCargoSelected &&
    formState.lastSireSelected &&
    formState.ownersDetailsSelected &&
    formState.itinerarySelected

  const anySelected =
    formState.lastThreeCargoSelected ||
    formState.lastSireSelected ||
    formState.ownersDetailsSelected ||
    formState.itinerarySelected

  const renderAsAHTML = (htmlString: string) => ({ __html: htmlString })

  const getCargoPrefix = (index: number) => {
    switch (index) {
      case 0:
        return 'Last - '
      case 1:
        return (
          <>
            2<sup>nd</sup> -&nbsp;
          </>
        )
      case 2:
        return (
          <>
            3<sup>rd</sup> -&nbsp;
          </>
        )
      default:
        return
    }
  }

  function onChangeHandlerIndividual({
    key,
    isChecked,
  }: {
    key: keyof typeof defaultFormState
    isChecked: boolean
  }) {
    setFormState({
      ...formState,
      [key]: isChecked,
    })
  }

  function onChangeHandlerAll({ isChecked }: { isChecked: boolean }) {
    setFormState({
      lastThreeCargoSelected: isChecked,
      lastSireSelected: isChecked,
      ownersDetailsSelected: isChecked,
      itinerarySelected: isChecked,
    })
  }

  return (
    <>
      <FormControlLabel
        sx={{ mb: 3 }}
        label="Select all"
        control={
          <Checkbox
            checked={allSelected}
            onChange={(e, checked) =>
              onChangeHandlerAll({ isChecked: checked })
            }
          />
        }
      />

      <FormControlLabel
        label="Last three cargos"
        control={
          <Checkbox
            checked={formState.lastThreeCargoSelected}
            onChange={(e, checked) =>
              onChangeHandlerIndividual({
                key: 'lastThreeCargoSelected',
                isChecked: checked,
              })
            }
          />
        }
      />

      <FormControlLabel
        label="Last SIRE"
        control={
          <Checkbox
            checked={formState.lastSireSelected}
            onChange={(e, checked) =>
              onChangeHandlerIndividual({
                key: 'lastSireSelected',
                isChecked: checked,
              })
            }
          />
        }
      />

      <FormControlLabel
        label="Owner's details"
        control={
          <Checkbox
            checked={formState.ownersDetailsSelected}
            onChange={(e, checked) =>
              onChangeHandlerIndividual({
                key: 'ownersDetailsSelected',
                isChecked: checked,
              })
            }
          />
        }
      />

      <FormControlLabel
        label="Itinerary"
        control={
          <Checkbox
            checked={formState.itinerarySelected}
            onChange={(e, checked) =>
              onChangeHandlerIndividual({
                key: 'itinerarySelected',
                isChecked: checked,
              })
            }
          />
        }
      />

      <Box
        component={Button}
        sx={{ mt: 5, maxWidth: '100%' }}
        onClick={() => copyToClipboardHandler()}
        icon={<FileCopyIcon size={16} />}
        variant="primary"
        disabled={!anySelected}
      >
        Copy to clipboard
      </Box>

      <div style={{ display: 'none' }} ref={hiddenAreaWithCopiedDataRef}>
        {formState.lastThreeCargoSelected && (
          <>
            <div style={{ fontWeight: 'bold' }}>LAST THREE CARGOS</div>
            {lastThreeVoyages?.map((voyage, index) => {
              const cargoPrefix = getCargoPrefix(index)

              return (
                <div key={voyage._id}>
                  {cargoPrefix}
                  {voyage.productCodes || voyage.cargoGrades}
                </div>
              )
            })}
          </>
        )}
        {formState.lastSireSelected && (
          <div>
            <br />
            <br />
            <div style={{ fontWeight: 'bold' }}>LAST SIRE</div>
            <div style={{ fontWeight: 'bold' }}>Approval</div>
            <div>{lastSire?.approval}</div>
            <div style={{ fontWeight: 'bold' }}>Inspection date</div>
            <div>
              {lastSire?.inspectionDate &&
                toDateFormat(lastSire?.inspectionDate)}
            </div>
            <div style={{ fontWeight: 'bold' }}>Location</div>
            <div>{lastSire?.port}</div>
          </div>
        )}
        {formState.ownersDetailsSelected && (
          <div>
            <br />
            <br />
            <div style={{ fontWeight: 'bold' }}>OWNER&apos;S DETAILS</div>
            <div style={{ fontWeight: 'bold' }}>Registered owner</div>
            <div>
              <div
                dangerouslySetInnerHTML={renderAsAHTML(
                  registeredOwner?.company ?? '',
                )}
              />
              <div
                dangerouslySetInnerHTML={renderAsAHTML(
                  registeredOwner?.address ?? '',
                )}
              />
              <div
                dangerouslySetInnerHTML={renderAsAHTML(
                  registeredOwner?.email ?? '',
                )}
              />
              <div
                dangerouslySetInnerHTML={renderAsAHTML(
                  registeredOwner?.website ?? '',
                )}
              />
              <div
                dangerouslySetInnerHTML={renderAsAHTML(
                  registeredOwner?.phone ?? '',
                )}
              />
            </div>
            <br />
            <div style={{ fontWeight: 'bold' }}>Commercial operator</div>
            <div>
              <div
                dangerouslySetInnerHTML={renderAsAHTML(
                  commercialOperator?.company ?? '',
                )}
              />
              <div
                dangerouslySetInnerHTML={renderAsAHTML(
                  commercialOperator?.address ?? '',
                )}
              />
              <div
                dangerouslySetInnerHTML={renderAsAHTML(
                  commercialOperator?.email ?? '',
                )}
              />
              <div
                dangerouslySetInnerHTML={renderAsAHTML(
                  commercialOperator?.website ?? '',
                )}
              />
              <div
                dangerouslySetInnerHTML={renderAsAHTML(
                  commercialOperator?.phone ?? '',
                )}
              />
            </div>
            <br />
            <div style={{ fontWeight: 'bold' }}>Disponent owner</div>
            <div>
              <div
                dangerouslySetInnerHTML={renderAsAHTML(
                  disponentOwner?.company ?? '',
                )}
              />
              <div
                dangerouslySetInnerHTML={renderAsAHTML(
                  disponentOwner?.address ?? '',
                )}
              />
              <div
                dangerouslySetInnerHTML={renderAsAHTML(
                  disponentOwner?.email ?? '',
                )}
              />
              <div
                dangerouslySetInnerHTML={renderAsAHTML(
                  disponentOwner?.website ?? '',
                )}
              />
              <div
                dangerouslySetInnerHTML={renderAsAHTML(
                  disponentOwner?.phone ?? '',
                )}
              />
            </div>
            <br />
            <div style={{ fontWeight: 'bold' }}>Technical operator</div>
            <div>
              <div
                dangerouslySetInnerHTML={renderAsAHTML(
                  technicalOperator?.company ?? '',
                )}
              />
              <div
                dangerouslySetInnerHTML={renderAsAHTML(
                  technicalOperator?.address ?? '',
                )}
              />
              <div
                dangerouslySetInnerHTML={renderAsAHTML(
                  technicalOperator?.email ?? '',
                )}
              />
              <div
                dangerouslySetInnerHTML={renderAsAHTML(
                  technicalOperator?.website ?? '',
                )}
              />
              <div
                dangerouslySetInnerHTML={renderAsAHTML(
                  technicalOperator?.phone ?? '',
                )}
              />
            </div>
            <br />
            <div style={{ fontWeight: 'bold' }}>Banking details</div>
            <div>{bankingDetails?.header || ''}</div>
            <div>{bankingDetails?.company || ''}</div>
            {bankingDetails?.address
              ?.split('\n')
              .map((addressLine) => <div key={addressLine}>{addressLine}</div>)}
            <div style={{ fontWeight: 'bold' }}>ACCOUNT NO</div>
            <div>{bankingDetails?.accountNumber || ''}</div>
            <div style={{ fontWeight: 'bold' }}>IBAN</div>
            <div>{bankingDetails?.IBAN || ''}</div>
            <div style={{ fontWeight: 'bold' }}>SWIFT</div>
            <div>{bankingDetails?.SWIFT || ''}</div>
            <div style={{ fontWeight: 'bold' }}>CURRENCY</div>
            <div>{bankingDetails?.currency || ''}</div>
          </div>
        )}

        {formState.itinerarySelected && (
          <div>
            <br />
            <br />
            <span style={{ fontWeight: 'bold' }}>ITINERARY</span>

            {currentVoyage ? (
              <>
                <div>
                  <strong>Vessel: </strong>
                  {vesselData?.vesselName ?? ''}
                </div>

                <div>
                  <strong>Cargo grade: </strong>
                  {currentVoyage.cargoGrades}
                </div>

                {currentVoyageStatus && (
                  <div>
                    <strong>Status: </strong>
                    {capitalizeStringWords(currentVoyageStatus)}
                  </div>
                )}

                <br />
                <br />

                {upcomingItineraryData.length > 0 &&
                  upcomingItineraryData.map((upcomingItem) => {
                    const {
                      portName,
                      portFunction,
                      arrivalLocal,
                      departureLocal,
                    } = upcomingItem

                    const [firstDischargePort, lastDischargePort] = [
                      upcomingDischargePorts[0],
                      upcomingDischargePorts[upcomingDischargePorts.length - 1],
                    ]
                    const transformedPortFunction =
                      capitalizeStringWords(portFunction)

                    return (
                      <>
                        {portFunction === PortFunction.DISCHARGING &&
                          upcomingDischargePorts.length > 1 && (
                            <>
                              <div>
                                <strong>
                                  {`First ${PORT_FUNCTION_LABELData[transformedPortFunction]}`}
                                  :{' '}
                                </strong>

                                {capitalizeStringWords(
                                  firstDischargePort.portName,
                                )}
                              </div>

                              <div>
                                <strong>
                                  {`Last ${PORT_FUNCTION_LABELData[transformedPortFunction]}`}
                                  :{' '}
                                </strong>

                                {capitalizeStringWords(
                                  lastDischargePort.portName,
                                )}
                              </div>
                            </>
                          )}

                        <div>
                          <strong>
                            {PORT_FUNCTION_LABELData[transformedPortFunction]}:{' '}
                          </strong>

                          {capitalizeStringWords(portName)}
                        </div>

                        {
                          // Don't show the ETA for the vessel's current location (if it's at a port)
                          // or if the item has the purpose of Commencing
                          activeItineraryItem !== upcomingItem &&
                            transformedPortFunction !==
                              PortFunction.COMMENCING && (
                              <div>
                                <strong>ETA: </strong>

                                {arrivalLocal
                                  ? toDateTimeFormat(arrivalLocal)
                                  : 'Unknown'}
                              </div>
                            )
                        }

                        <div>
                          <strong>ETS: </strong>
                          {departureLocal
                            ? toDateTimeFormat(departureLocal)
                            : 'Unknown'}
                        </div>

                        <br />
                      </>
                    )
                  })}
              </>
            ) : (
              <div>No data available.</div>
            )}
          </div>
        )}
      </div>
    </>
  )
}
