/* eslint-disable import/imports-first */
import { useAuth0 } from '@auth0/auth0-react'
import { Link } from '@maersktankersdigital/web-components'
import { Box, Toolbar } from '@mui/material'
import { createBrowserHistory } from 'history'
import { PropsWithChildren, Suspense, useEffect } from 'react'
import TagManager from 'react-gtm-module'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { SWRConfig } from 'swr'
import { ApiConfig } from '~api'
import LoadComponent from '~components/molecules/load-component/load-component'
import { Footer } from '~components/navigation-and-footer/footer/footer'
import { TopNavigation } from '~components/navigation-and-footer/navigation/top-navigation/top-navigation'
import { BaseStoreState, useStore } from '~context/store-provider'
import { UserRole, UserRoleName } from '~context/user-provider'
import { ExactScopeName, useHasScope } from '~hooks/permissions/use-has-scope'
import { useIsHighLevelUser } from '~hooks/permissions/use-is-high-level-user'
import { useGetMe } from '~hooks/queries/me/use-get-me'
import { UseGetRoles } from '~hooks/queries/roles/use-get-roles'
import { useNavAndFooterConstants } from '~hooks/use-nav-and-footer-constants'
import useOld from '~hooks/use-old'
import '~theme/deprecated-vt/deprecated-vt-styles/global-styles'
import { setTokenGetter } from '~utils/auth'
import { PageRoute } from '../constants/page-route'

export const browserHistory = createBrowserHistory()

const useScrollToTop = () => {
  const { pathname } = useLocation()
  const oldPathname = useOld(pathname)

  const pageChanged = !oldPathname || pathname !== oldPathname

  if (pageChanged) {
    window.scrollTo(0, 0)
  }
}

const DataWrapper = ({
  store,
  children,
}: PropsWithChildren<{ store: BaseStoreState }>) => {
  const navigate = useNavigate()
  const { isAuthenticated, user } = useAuth0()
  const isEmailVerified = user?.email_verified

  useEffect(() => {
    if (isAuthenticated && !isEmailVerified) {
      return navigate(PageRoute.VERIFY_EMAIL)
    }
  }, [isAuthenticated, isEmailVerified])

  const { data: userInfo } = useGetMe()
  const { data: userRoles } = UseGetRoles()

  useEffect(() => {
    if (!isAuthenticated || !userInfo || !userRoles) return

    TagManager.dataLayer({
      dataLayer: { pageType: 'homepage' },
    })

    store.User.setRoleData({
      vesselRoles: userRoles.vessel,
      poolpartnerRoles: userRoles.poolpartner,
      roles: convertRolesToObjects(userInfo.scope),
      sub: userInfo.sub,
    })

    if (!userInfo?.name) return

    store.User.setInfoData({
      department: userInfo.department ?? '',
      email: userInfo.email,
      name: userInfo.name,
      phoneNumber: userInfo.phoneNumber,
      secondaryPhoneNumber: userInfo.secondaryPhoneNumber,
      settings: userInfo.settings,
    })
  }, [isAuthenticated, userRoles, userInfo])

  return <>{children}</>
}

export const App = () => {
  const store = useStore()
  const location = useLocation()
  const { footerHeight, topNavigationHeight } = useNavAndFooterConstants()
  const { isAuthenticated, user, getAccessTokenSilently } = useAuth0()
  const isEmailVerified = user?.email_verified
  const isTechnicalManager = useHasScope(ExactScopeName.technicalManager)
  const isMTUser = useHasScope(ExactScopeName.maerskTankersUser)
  const isHighLevelUser = useIsHighLevelUser()
  const isPoolPartner = useHasScope(ExactScopeName.poolPartner)
  const isSuperReader = useHasScope(ExactScopeName.superReader)

  // Handles scrolling back to the top of the page after navigation
  useScrollToTop()

  const isNavVisible =
    isHighLevelUser ||
    isSuperReader ||
    isMTUser ||
    isPoolPartner ||
    isTechnicalManager

  useEffect(() => {
    setTokenGetter(getAccessTokenSilently)
  }, [getAccessTokenSilently])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100%' }}>
      <SWRConfig value={ApiConfig.swrDefaults}>
        <DataWrapper store={store}>
          <Box
            sx={{
              position: 'relative',
            }}
          >
            <Box className="vt-body">
              <Suspense fallback={<LoadComponent />}>
                {!isAuthenticated ? (
                  <></>
                ) : isEmailVerified && isNavVisible ? (
                  <>
                    <TopNavigation />
                    <Toolbar sx={{ minHeight: topNavigationHeight }} />
                  </>
                ) : (
                  <Box
                    sx={{
                      minHeight: topNavigationHeight,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'end',
                      px: { xs: 2, sm: 3, md: 4 },
                    }}
                  >
                    {window.location.pathname !== '/profile' && (
                      <Link link="/profile" label="Profile" />
                    )}
                  </Box>
                )}
              </Suspense>
              <Box
                component="main"
                sx={{
                  width: '100%',
                  pb: footerHeight,
                }}
              >
                <Outlet />
              </Box>
            </Box>
            <Footer withOutLeftNav />
          </Box>
        </DataWrapper>
      </SWRConfig>
    </Box>
  )
}

const pagesWithDarkBg = [
  PageRoute.LANDING,
  PageRoute.VERIFY_EMAIL,
  PageRoute.CREATE_USER,
]

const logoProps = {
  width: 104,
  height: 24,
}

const convertRolesToObjects = (roles: string[]): UserRole[] => {
  return roles.map((role) => {
    const [entityType, entityId, name] = role.split(':')
    return { entityType, entityId, name: name as UserRoleName }
  })
}
