import { useQuery } from '@tanstack/react-query'
import { IUserInfoReadResponse } from '~api/user/read'
import { tenMinutes } from '~constants/constants'
import { apiBase } from '~utils/base-url'
import { useFetch } from '~utils/fetch-request'

export function useGetMe() {
  const fetchRequest = useFetch<IUserInfoReadResponse>()
  return useQuery({
    queryKey: ['/me'],
    queryFn: () => fetchRequest(`${apiBase()}/me`),
    staleTime: tenMinutes,
  })
}
